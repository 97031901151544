<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status','goods_logo','goods_put']"
            ref="list"
			:is_created_get_list="false"
			:submit_preprocessing="submit_preprocessing"
        >
            <template slot="left_btn">
                <!-- <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加冰柜</a-button> -->
            </template>
			<template slot="right_btn">
                <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.export/freezer"></export-table>
            </template>
            <template slot="status" slot-scope="data">
                <a-tag
                    :color="data.text == 0 ? config.color.error : config.color.primary"
                    v-for="item in status_list"
                    :key="item.key"
                    v-show="item.key == data.text"
                >{{item.value}}</a-tag>
            </template>
            <template slot="operation" slot-scope="data">
                <a-divider type="vertical" />
                <a @click="look_details(data.record)">查看</a>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>
        <RightDrawer :visible.sync="visible_drawer">
            <template slot="content">
                <div v-for="(item,index) in locus_list" :key="index">
                    <div class="drawer_title" style>{{item.type == 1 ? '冰柜绑定' : '冰柜解绑'}}</div>
                    <a-descriptions>
                        <template v-if="item.type == 1">
                            <a-descriptions-item :span="3" label="投放客户">{{item.customer_name}}</a-descriptions-item>
                            <a-descriptions-item :span="3" label="客户地址">{{item.address}}</a-descriptions-item>
                            <a-descriptions-item :span="3" label="联系方式">{{item.contacts_phone}}</a-descriptions-item>
                            <a-descriptions-item :span="3" label="投放开始时间">{{item.start_time}}</a-descriptions-item>
                            <a-descriptions-item :span="3" label="投放结束时间">{{item.end_time}}</a-descriptions-item>
                        </template>
                        <template v-else-if="item.type == 2 || item.type == -1 || item.type == 3 ">
                            <a-descriptions-item
                                v-if="item.type == 2"
                                :span="3"
                                label="解绑原因"
                            >{{item.reason}}</a-descriptions-item>
                        </template>
                        <a-descriptions-item :span="3" label="操作时间">{{item.create_time}}</a-descriptions-item>
                        <a-descriptions-item :span="3" label="人员">{{item.username}}</a-descriptions-item>
                    </a-descriptions>
                </div>
            </template>
        </RightDrawer>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import RightDrawer from "@/components/RightDrawer";
import { investmentList } from "@/api/re";

let columns = [
	{
		title:"部门",
		dataIndex:"department"
	},
    {
        title: "发放人",
        dataIndex: "goods_title",

    },
    {
        title: "客户编号",
        dataIndex: "goods_model",
    },
    {
        title: "客户类型",
        dataIndex: "goods_code",
    },
    {
        title: "客户名称",
        dataIndex: "make_time",

    },
    {
        title: "城/乡",
        dataIndex: "deposit",
    },
    {
        title: "客户地址",
        dataIndex: "customer_name",
    },

    {
        title: "客户电话",
        dataIndex: "address",
        scopedSlots: {
            customRender: "address"
        }
    },
    {
        title: "投放类型",
        dataIndex: "username",
    },
    {
        title: "投放品项",
        dataIndex: "start_time",
    },
    {
        title: "投放数量",
        dataIndex: "end_time",
    },
    {
        title: "投放时间",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

let status_list = [
    {
        key: 1,
        value: "已投放"
    },
    {
        key: 0,
        value: "未投放"
	},
	{
        key: -1,
        value: "报废"
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        RightDrawer
    },
    data() {
        return {
			get_table_list: finvestmentList,
			submit_preprocessing: {
                array_to_string: ['department']
            },
            config: this.$config,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            status_list,
            locus_list: [],
            details: {},
            visible: false,
            visible_drawer: false,
            form_data_seo: {
                list: [
					{
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
					
					{
					    type: 'select',
					    title: '客户名称',
					    name: 'status',
					    options: {},
					    list: status_list
					},
					{
					    type: 'select',
					    title: '品项名称',
					    name: 'status',
					    options: {},
					    list: status_list
					},
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },

                ],
                ...this.$config.form_data_seo
            },
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'radio',
                        title: '状态',
                        name: 'status',
                        options: {},
                        list: [
                            {
                                key: -1,
                                value: '报废'
                            },
                            {
                                key: 0,
                                value: '启用'
                            }
                        ]
                    },

                    // {
                    //     type: 'text',
                    //     name: 'goods_title',
                    //     title: '商品名称',
                    //     options: {

                    //     },
                    // },

                ]
            }
        };
    },
    async created() {
		let query = this.$route.query;
		this.$method.get_department().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list,'department','treeData',res)
		});

		this.$nextTick(()=>{
			this.$refs.list.fixed_seo_data = {
				...query,
			}
			this.$refs.list.get_list();
		})
    },
	destroyed(){
	},
    methods: {
        look_details(record) {
            this.visible_drawer = true;
            freezerLocus({
                data: {
                    binding_id: record.id
                }
            }).then(res => {
                this.locus_list = res.data.list;
            })
        },
        add() {
            this.form_data.title = "添加"
            this.visible = true;
            this.form_data.list.forEach(item => {
                item.options.initialValue = "";
                if (item.type == 'upload') {
                    item.options.initialValue = [];
                }
            })
        },
        edit(record) {
            this.form_data.title = "编辑"
            let data = record
            this.form_data.list.forEach(item => {
                if (item.type == 'upload') {
                    let val = {};
                    val[item.name] = {
                        fileList: [{
                            url: data[item.name]
                        }]
                    }
                    item.options.initialValue = [{
                        uid: data[item.name],
                        name: data[item.name],
                        status: 'done',
                        url: data[item.name]
                    }];

                } else {
                    item.options.initialValue = data[item.name];
                }
            })
            this.visible = true;
        },

        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }

            changeFreezer({
                data: {
                    ...data
                },
            }).then(res => {
                this.$message.success(res.info);
                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.drawer_title {
    padding: 15px 0;
}
</style>